import Hero from "../components/Hero";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden pt-4 bg-gradient-to-b from-main-turquoiseLight to-main-turquoise">
      <main className="flex-grow">
        <Hero />
        <section className="relative">
          {/* Section background (needs .relative class on parent and next sibling elements) */}
          <div
            className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
            aria-hidden="true"
          ></div>
          <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-12 md:pt-20">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h2 mb-4">Privacyverklaring</h1>
                <p className="text-xl text-gray-600">
                  Ons website-adres is: https://www.beerandfoodfestival.nl.
                </p>
                <p className="text-xl text-gray-600">
                  Privacyverklaring (& Cookiebeleid) Beer & Food Festival
                </p>
                <p className="text-xl text-gray-600">Versie: 29 april 2023</p>
                <p className="text-xl text-gray-600">Inhoudsopgave:</p>
                <ol className="text-xl text-gray-600 list-disc pl-6">
                  <li>Ons beleid in het kort</li>
                  <li>
                    Wie is verantwoordelijk voor de verwerking van mijn
                    persoonsgegevens?
                  </li>
                  <li>Wanneer is dit privacy beleid van toepassing?</li>
                  <li>Wat zijn persoonsgegevens?</li>
                  <li>
                    Van wie verwerken wij persoonsgegevens en hoe krijgen wij
                    deze gegevens?
                  </li>
                  <li>
                    Welke gegevens verwerken wij van jou, waarvoor gebruiken wij
                    die en hoe lang worden deze bewaard?
                  </li>
                  <li>Met wie worden deze gegevens gedeeld?</li>
                  <li>Waar slaan wij jouw gegevens op?</li>
                  <li>
                    Hoe gaan wij om met gegevens van onze zakelijk en
                    particuliere klanten?
                  </li>
                  <li>Hoe worden jouw gegevens beveiligd?</li>
                  <li>
                    Welke rechten kun jij uitoefenen met betrekking tot jouw
                    gegevens?
                  </li>
                  <li>Vragen?</li>
                </ol>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Ons beleid in het kort</h1>
                <p className="text-xl text-gray-600">
                  Gezien dit een lang (en technisch) document is wat legaal
                  nodig is hierbij een korte samenvatting:
                </p>
                <p className="text-xl text-gray-600">
                  Wij slaan geen gegevens op die niet 100% nodig zijn. Er is
                  momenteel één moment waarop wij gegevens op slaan en dat zijn
                  de gegevens die jij invult tijdens een aankoop, namelijk jouw
                  naam en email adres. Deze gegevens worden opgeslagen omdat dit
                  nodig is om de gekochte artikelen op de dag van het evenement
                  uit te delen en zo te kunnen valideren dat we de correcte
                  persoon hebben en om mogelijk contact op te nemen als er iets
                  mis gaat.
                </p>
                <p className="text-xl text-gray-600">
                  Wil je deze gegevens (als nog) in zien neem dan contact met
                  ons op, deze methoden kunnen gevonden worden in het kopje
                  "Vragen"
                </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Wie is verantwoordelijk voor de verwerking van mijn persoonsgegevens?</h1>
                <p className="text-xl text-gray-600">Het Beer & Food Festival wordt georganiseerd door medewerkers van Café de Beurs te Klappeijstraat 4-6, 4901 HE Oosterhout, hierom is dit de verantwoordelijke partij voor de verwerking van gegevens zoals beschreven in dit privaybeleid. Deze partij kan zowel Café de Beurs als Beer & Food Festival worden genoemd in dit document.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Wanneer is dit privacy beleid van toepassing?</h1>
                <p className="text-xl text-gray-600">Dit privacy beleid is van toepassing op alle persoonsgegevens die worden verwerkt van iedereen die weleens contact heeft (gehad) met (de organisatie van) het Beer & Food Festival, zoals onze bezoekers, klanten en zakelijke contactpersonen, op alle aan Beer & Food Festival gerelateerde domeinen.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Wat zijn persoonsgegevens?</h1>
                <p className="text-xl text-gray-600">Persoonsgegevens zijn alle gegevens die herleidbaar zijn tot jou als individu. Denk maar aan jouw naam, telefoonnummer, adres of e-mailadres. Maar ook gegevens zoals je IP-adres of Café de Beurs klantnummer zijn persoonsgegevens. En andere gegevens die uniek zijn voor jou, zoals je Café de Beurs bestelgeschiedenis of surfgedrag.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Van wie verwerken wij persoonsgegevens en hoe krijgen wij deze gegevens?</h1>
                <p className="text-xl text-gray-600">Wij verwerken de persoonsgegevens van iedereen die direct of indirect contact heeft (gehad) met Het Beer and Food Festival, zoals particuliere en zakelijke klanten.</p>
                <p className="text-xl text-gray-600">We krijgen de gegevens rechtstreeks van jou, wanneer je onze website bezoekt, en een actie onderneemt waarbij je akkoord dient te gaan met ons privay beleid.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Welke gegevens verwerken wij van jou, waarvoor gebruiken wij die en hoelang worden deze bewaard?</h1>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Gegevens voor het verwerken van jouw bestelling:</p>
                <p className="text-xl text-gray-600">Als jij iets bestelt, hebben we bepaalde gegevens van je nodig om jouw bestelling te kunnen klaarmaken en te leveren. Hiervoor verzamelen wij je naam, en email adres. Gezien onze betalingen lopen via de service van Mollie is het mogelijk dat meer gegevens door hun worden verzameld, deze staan dan in hun beveiligde database opgeslagen en dit privaybeleid is <a className="underline" href="https://www.mollie.com/nl/cookies" target="_blank">hier</a> te vinden.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Gegevens over jouw contact via de mail:</p>
                <p className="text-xl text-gray-600">Je kan ons een bericht sturen via verschillende methoden, echter komt dit allemaal via de mail binnen. Deze berichten bewaren wij maximaal 7 jaar, echter is dit in de praktijk vaak korter.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Personalisatie op basis van jouw surf- en aankoopgeschiedenis bij Beer & Food Festival:</p>
                <p className="text-xl text-gray-600">Onze website bevat geen cookies die data van jou bewaren en/of delen met als doel (gerichte) reclame te kunnen maken. Het enige doeleinde van de opslag van jouw gegevens is het valideren en controleren van bestellingen.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Analyse:</p>
                <p className="text-xl text-gray-600">Uit pure intresse is het mogelijk dat wij jouw gegevens inkijken om het koopgedrag van onze bezoekers te analyseren en voor toekomstige edities te gebruiken voor vergelijkingen. Deze analyses gebeuren intern en zullen niet met derden gedeeld worden. Mocht u hier bezwaar tegen hebben dan kunt u altijd contact met ons opnemen.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">De overheid:</p>
                <p className="text-xl text-gray-600">Soms moeten wij persoonsgegevens doorgeven aan de overheid. Dit kan zich allereerst voordoen als bepaalde overheidsinstellingen deze gegevens nodig hebben voor de uitvoering van hun taken, zoals de Belastingdienst. Ook kan de politie of justitie bepaalde gegevens nodig hebben in het geval van fraude of misbruik. Tot slot kunnen bepaalde toezichthouders toegang tot persoonsgegevens krijgen in het kader van een onderzoek.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Hoe gaan wij om met gegevens van onze particuliere en zakelijke klanten?</h1>
                <p className="text-xl text-gray-600">Met gegevens van onze particuliere en zakelijke klanten gaan wij zorgvuldig om. </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Waar slaan wij jouw gegevens op?</h1>
                <p className="text-xl text-gray-600">Wij slaan jouw gegevens op binnen een beveiligde database binnen Nederland.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Hoe worden jouw gegevens beveiligd?</h1>
                <p className="text-xl text-gray-600">Café de Beurs neemt veel maatregelen om jouw persoonsgegevens te beveiligen. Zowel op organisatorisch als technisch gebied. Wij hebben een groep specialisten in huis die dagelijks werken aan het beveiligen van onze systemen en het zoeken naar kwetsbaarheden. Door een strenge toegangscontrole zorgen wij dat jouw persoonsgegevens alleen toegankelijk zijn voor medewerkers waarvoor het noodzakelijk is dat zij daarmee werken. Daarnaast laten we bij Café de Beurs regelmatig onze beveiliging toetsen door externe experts. Geven we jouw gegevens aan iemand anders? Dan eisen we dat die ander net zo zorgvuldig met jouw gegevens omgaat als wij. Mocht je het gevoel hebben dat dit niet gebeurt, laat het ons dan weten via onze klantenservice.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Welke rechten kun jij uitoefenen met betrekking tot jouw gegevens?</h1>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht op informatie:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht op een begrijpelijke en transparante uitleg over hoe wij met jouw persoonsgegevens omgaan en welke rechten jij in dat verband kan uitoefenen. In dit privacybeleid hebben wij daarom uitgebreid uitgelegd welke gegevens wij van jou verzamelen en hoe wij met jouw gegevens omgaan.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht op inzage:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om te allen tijde inzage van ons te vragen in de gegevens die wij over jou beschikbaar hebben. Je kunt dat per mail aanvragen. Aanvragen verwerken wij binnen uiterlijk 30 dagen.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht op correctie:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om jouw persoonsgegevens te laten corrigeren als deze onjuist of verouderd zijn en/of om ze te laten aanvullen als ze onvolledig zijn.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht om bezwaar te maken:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om bezwaar te maken tegen de verwerking van jouw gegevens als jij het niet eens bent met de manier waarop we jouw persoonsgegevens verwerken. Dit recht geldt voor de gegevens die wij gebruiken voor direct marketing. Zo kun je ons vragen om jouw gegevens niet langer te gebruiken voor gepersonaliseerde aanbevelingen op de website. Dit kun je zelf regelen in je account. Daarnaast geldt dit recht ook voor andere gegevens die we van je gebruiken op basis van ons gerechtvaardigd belang. Je kunt er bijvoorbeeld voor kiezen dat we de gegevens over je surf- en zoekgedrag vergeten en blijven vergeten. Ook dit kun je zelf regelen in je account. Aanvragen verwerken wij binnen uiterlijk 30 dagen.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht op dataportabiliteit:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om gegevens die jij aan ons hebt gegeven in het kader van de overeenkomst(en) die je met ons hebt gesloten te ontvangen in een machine-leesbaar formaat, zodat jij deze gegevens kan bewaren in een database van jou of van een andere partij. Dit betreffen jouw naam-, adres- en woonplaatsgegevens en bestelgeschiedenis. Wij bieden jou de mogelijkheid om deze gegevens in een machine-leesbaar formaat te downloaden in account.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht op beperking:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om beperking van de verwerking van jouw gegevens te verzoeken. Dit betekent dat wij jouw gegevens mogen bewaren maar niet gebruiken. Dit recht ontstaat in een aantal gevallen. Als je van mening bent dat hier sprake van is, kun je contact met ons opnemen via de klantenservice.</p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht om vergeten te worden:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om ons te verzoeken om alle gegevens die wij van jou hebben te verwijderen. Wanneer jij een verzoek indient om jouw account te verwijderen, zullen wij gegevens verwijderen die tot jou herleidbaar zijn behalve de gegevens die wij op basis van de wet moeten of mogen bewaren. Wij bieden jou de mogelijkheid om een verzoek per mail in te dienen.  </p>
                <p className="text-2xl pb-1 pt-2 text-gray-800">Recht om een klacht in te dienen:</p>
                <p className="text-xl text-gray-600">Jij hebt het recht om een klacht in te dienen over de wijze waarop wij met jouw gegevens omgaan. Wanneer jij een klacht hebt, dan lossen wij dat het liefst zelf met je op. Neem daarvoor contact op met onze klantenservice.  Tot slot heb je het recht om je met jouw klacht te wenden tot de Autoriteit Persoonsgegevens.</p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Vragen?</h1>
                <p className="text-xl text-gray-600">Als je nog vragen hebt over de wijze waarop Beer & Food Festival met jouw gegeven om gaat aarzel dan niet om contact met ons op te nemen, dit kan via verschillende wegen:</p>
                <ol className="text-xl text-gray-600 list-disc pl-6">
                  <li>Vul ons contactformulier onderaan de home pagina in</li>
                  <li>Stuur ons een email naar info@beerandfoodfestival.nl</li>
                  <li>
                    Bel met Café de Beurs (tijdens openingstijden) via 0162 45
                    34 77
                  </li>
                  <li>
                    Loop bij Café de Beurs naar binnen en vraag het aan een
                    medewerken (Let op: de meeste medewerkers hebben geen
                    toegang tot deze data en zullen u niet kunnen helpen, u
                    heeft de meeste kans door online contact met ons op te nemen
                    of naar Jindra Snobl te vragen).
                  </li>
                </ol>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Cookiebeleid van Beer & Food Festival</h1>
                <p className="text-xl text-gray-600">Versie van 28 maart 2023</p>
                <p className="text-xl text-gray-600">Op onze website worden geen gegevens opgeslagen doormiddel van Cookies, dit doen wij voor verschillende redenen waaronder:</p>
                <ol className="text-xl text-gray-600 list-disc pl-6">
                  <li>Het is niet nodig.</li>
                  <li>
                  Wij verzamelen geen data met als doeleinde verkoop aan derden en/of ander partijen.
                  </li>
                  <li>Dit valt buiten de filosofie waarin wij geloven.</li>
                </ol>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Slotopmerkingen</h1>
                <p className="text-xl text-gray-600 pb-12">Wij passen ons cookiebeleid aan wanneer dit op wat voor manier dan ook nodig is. Je kunt de actuele versie van dit cookiebeleid raadplegen door onderaan elke pagina te klikken op de link naar het privacy & cookiebeleid van Beer & Food Festival.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Privacy;

import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";

function Hero() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto">
        {/* Hero content */}
        <Navbar />
        <Outlet />
      </div>
    </section>
  );
}

export default Hero;

import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="text-center mx-auto pb-8 md:pb-12">
      <div className="flex md:flex-row flex-col justify-between">
        <Link to="/">
          <div className="flex md:mx-0 mx-auto w-[100px] h-[100px] focus:outline-none">
            <img
              className="focus:outline-none"
              width="100px"
              height="100px"
              src={"/logo192.png"}
              alt="Beer and Food Festival Logo"
            />
          </div>
        </Link>
        <div className="flex">
          <h1 className="mx-auto text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4">
            Beer and Food <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-700 to-blue-300">
              Festival 2023
            </span>
          </h1>
        </div>
        <div className="flex">
        </div>
      </div>
    </nav>
  );
}

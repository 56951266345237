import HeroHome from '../components/home/HeroHome';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden pt-4 bg-gradient-to-b from-main-turquoiseLight to-main-turquoise">
      <main className="flex-grow">
        <HeroHome />
      </main>
      <Footer />
    </div>
  );
}

export default Home

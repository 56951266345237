import React from "react";

import Footer from "../components/Footer";

function PageNotFound() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-gradient-to-b from-main-turquoiseLight to-main-turquoise">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                {/* 404 content */}
                <h1 className="h1 mb-4">Die pagina bestaat niet</h1>
                <img
                  width={480}
                  height={270}
                  className="mx-auto"
                  src={"/404.gif"}
                  alt="404"
                />
                <div className="mt-8">
                  <a
                    href="/"
                    className="btn text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Ga terug naar de home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default PageNotFound;

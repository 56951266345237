import React from "react";
import Navbar from "../Navbar";
import { Outlet } from "react-router-dom";

function HeroHome() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto">
        {/* Hero content */}
        <div className="pb-8 md:pb-12">
          {/* Section header */}
          <Navbar />
          <Outlet />

          <div className="max-w-3xl mx-auto flex justify-center pb-8">
            <a href="https://www.instagram.com/beerandfoodfestival/" target="_blank" className="flex">
              <img
                className="flex mx-auto pr-4"
                src="/instagram.png"
                alt="https://www.instagram.com/beerandfoodfestival/"
                width={75}
                height={75}
              />
            </a>
            <a href="https://www.facebook.com/BeerandFoodFestivalOosterhout" target="_blank" className="flex">
              <img
                className="flex mx-auto pl-4"
                src="/facebook.png"
                alt="https://www.facebook.com/BeerandFoodFestivalOosterhout"
                width={75}
                height={75}
              />
            </a>
          </div>

          <div className="max-w-3xl mx-auto">
            <p className="text-xl text-gray-600 mb-8 px-2">
              Enorm bedankt voor het bezoeken van en deelnemen aan de derde editie van het Beer & Food Festival! Op zowel deze website als onze socials komen gedurende het jaar updates over de vierde editie, dus hou deze in de gaten!
            </p>
          </div>

          {/* Hero image */}
          <div>
            <div className="relative flex justify-center mb-8 px-2">
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto"
                  src={"/banner.png"}
                  alt="Beer and Food Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;

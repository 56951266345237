import Hero from "../components/Hero";
import Footer from "../components/Footer";

const Levering = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden pt-4 bg-gradient-to-b from-main-turquoiseLight to-main-turquoise">
      <main className="flex-grow">
        <Hero />
        <section className="relative">
          {/* Section background (needs .relative class on parent and next sibling elements) */}
          <div
            className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
            aria-hidden="true"
          ></div>
          <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-12 md:pt-20">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h2 mb-4">Leveringsvoorwaarden</h1>
                <p className="text-xl text-gray-600">
                  Ons website-adres is: https://www.beerandfoodfestival.nl.
                </p>
                <p className="text-xl text-gray-600">
                  Levering (& Retour) beleid Beer & Food Festival
                </p>
                <p className="text-xl text-gray-600">Versie: 29 april 2023</p>
                <p className="text-xl text-gray-600">Inhoudsopgave:</p>
                <ol className="text-xl text-gray-600 list-disc pl-6">
                  <li>Hoe werkt de levering van gekochte items?</li>
                  <li>
                    Ik kan niet deelnemen aan het evenement, kan ik mijn geld
                    terug krijgen?
                  </li>
                  <li>
                    Het evenement gaat niet door, krijg ik mijn geld terug?
                  </li>
                  <li>
                    Ik heb een fout gemaakt in de bestelling, kan ik dit nog
                    aanpassen?
                  </li>
                  <li>Vragen?</li>
                </ol>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">
                  Hoe werkt de levering van gekochte items?
                </h1>
                <p className="text-xl text-gray-600">
                  Het Beer and Food Festival vindt in 2023 plaats op 24 juni, op
                  deze dag zijn uw gekochte items bij het daarvoor ingerichtte
                  punt bij Café de Beurs op te halen. Wij leveren niet aan u,
                  omdat het evenement in de Klappeijstraat plaats vindt en dit
                  dus niet "remote" te volgen is.
                </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">
                  Ik kan niet deelnemen aan het evenement, kan ik mijn geld
                  terug krijgen?
                </h1>
                <p className="text-xl text-gray-600">
                  In principe nemen wij geen aankopen terug, echter heeft u
                  wettelijk 14 dagen bedenktijd, mocht u dus binnen deze periode
                  uw aankoop ongedaan willen maken dan kunt u contact op nemen
                  met ons via het contactformulier, email
                  (info@beerandfoodfestival.nl) en/of Café de Beurs. Mocht u
                  buiten deze periode uw aankoop ongedaan willen maken dan kan u
                  nog steeds contact op nemen en zullen wij dit intern bespreken
                  en u binnen 5 werkdagen over onze beslissing inlichten.
                </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">
                  Het evenement gaat niet door, krijg ik mijn geld terug?
                </h1>
                <p className="text-xl text-gray-600">
                  Als wij vanuit de organisatie het evenement niet door laten
                  gaan krijgt u uw geld natuurlijk terug.
                </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">
                  Ik heb een fout gemaakt in de bestelling, kan ik dit nog
                  aanpassen?
                </h1>
                <p className="text-xl text-gray-600">
                  Als u een fout heeft gemaakt met uw bestelling raden wij u aan
                  binnen 14 dagen contact met ons op te nemen (gezien u dan
                  binnen de wettelijke bedenktijd valt) en dan passen wij dit
                  zonder problemen aan, mocht u buiten de bedenk tijd vallen dan
                  kunt u nog steeds contact met ons op nemen en maken wij een
                  interne beslissing die wij binnen 5 werkdagen aan u zullen
                  medelen.
                </p>
              </div>

              <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
                <h1 className="h3 mb-4">Vragen?</h1>
                <p className="text-xl text-gray-600">
                  Als je nog vragen hebt over hoe wij met bestellingen, het
                  leveren hier van en het wijzigen/retouren hiervan om gaan
                  aarzel dan niet om contact met ons op te nemen, dit kan via
                  verschillende wegen:
                </p>
                <ol className="text-xl text-gray-600 list-disc pl-6 pb-12">
                  <li>Vul ons contactformulier onderaan de home pagina in</li>
                  <li>Stuur ons een email naar info@beerandfoodfestival.nl</li>
                  <li>
                    Bel met Café de Beurs (tijdens openingstijden) via 0162 45
                    34 77
                  </li>
                  <li>
                    Loop bij Café de Beurs naar binnen en vraag het aan een
                    medewerken (Let op: de meeste medewerkers hebben geen
                    toegang tot deze data en zullen u niet kunnen helpen, u
                    heeft de meeste kans door online contact met ons op te nemen
                    of naar Jindra Snobl te vragen).
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Levering;

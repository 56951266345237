import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import PageNotFound from "./pages/404";
// import Bestel from "./pages/winkelwagen";
// import Admin from "./pages/admin";
// import OrderDetail, { loader } from "./pages/bestelling/[id]";
import Privacy from "./pages/privacy";
import Levering from "./pages/levering";
// import VotePage from "./pages/admin/stemmen";
// import ConfirmVote, { vote_loader } from "./pages/stem/[id]";

import "./styles/style.scss";
import "./styles/carousel.css";
// import Stem from "./pages/stem";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <PageNotFound />,
  },
  // {
  //   path: "winkelwagen",
  //   element: <Bestel />,
  // },
  // {
  //   path: "admin/bestellingen",
  //   element: <Admin />,
  // },
  // {
  //   path: "admin/stemmen",
  //   element: <VotePage />,
  // },
  // {
  //   path: "bestelling/:id",
  //   element: <OrderDetail />,
  //   loader: loader,
  // },
  // {
  //   path: "stem/",
  //   element: <Stem />
  // },
  // {
  //   path: "stem/:id",
  //   element: <ConfirmVote />,
  //   loader: vote_loader,
  // },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "levering",
    element: <Levering />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default function App() {
  return <></>;
}

root.render(
  <React.StrictMode>
    <div className="bg-gradient-to-b from-main-turquoiseLight to-main-turquoise">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Copyrights note */}
          <div className="text-sm text-slate-200 mr-4">
            &copy; 2023 Beer and Food Festival in samenwerking met Café de
            Beurs. Alle rechten voorbehouden.
          </div>
          {/* Copyrights note */}
          <div className="text-sm text-slate-200 mr-4 hover:text-slate-400">
            <Link to="/privacy">Privacyverklaring</Link>
          </div>
          {/* Copyrights note */}
          <div className="text-sm text-slate-200 mr-4 hover:text-slate-400">
            <Link to="/levering">Leveringsvoorwaarden</Link>
          </div>

          {/* Social links */}
          <div className="flex justify-center md:py-0 py-4">
            <a
              href="https://www.instagram.com/beerandfoodfestival/"
              target="_blank"
              className="flex"
            >
              <img
                className="flex mx-auto pr-4"
                src="/instagram.png"
                alt="https://www.instagram.com/beerandfoodfestival/"
                width={75}
                height={75}
              />
            </a>
            <a
              href="https://www.facebook.com/BeerandFoodFestivalOosterhout"
              target="_blank"
              className="flex"
            >
              <img
                className="flex mx-auto pl-4"
                src="/facebook.png"
                alt="https://www.facebook.com/BeerandFoodFestivalOosterhout"
                width={75}
                height={75}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
